import React, { useState, useEffect, act } from "react";
import axios from "axios";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import { CardHeader, Box, TextField, Typography,Button } from '@mui/material';
import { styled } from '@mui/system';
import Plot from 'react-plotly.js';
import PopoverComponent from "./Popover";
import Spinner from "./Spinner";
import { useGraphContext } from '../GraphContext';
import two_way_arrow from '../assets/two_way_arrow.png';
import closing_icon from '../assets/closing_icon.png';
import { useResetContext } from '../ResetContext';
import downloadTemplate from '../assets/downloadTemplate.webp'; 
import clear_filters from '../assets/clear_filters.png';
import trash from '../assets/trash.png';

const CustomCardHeader = styled(CardHeader)(({ theme, isActive }) => ({
    color: 'rgb(48, 60, 73)', // Change text color to white when active
    backgroundColor: isActive ? 'rgb(203, 211, 220)' : 'white', // Change background to black when active
    padding: '10px 10px',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: 'MAN EUROPE_regular',
    fontWeight: 'bold',
    '& .MuiCardHeader-title': {
        color: 'rgb(48, 60, 73)', // Change title color to white when active
        fontSize: 18,
        fontFamily: 'MAN EUROPE_regular',
        fontWeight: 'bold',
    }
}));


const ScrollableContainer = styled(Box)({
    maxHeight: '300px',
    overflowY: 'auto',
    margin: '0px',
    padding: '0px'
});

const CustomCardHeaderWithPopover = ({ title, id, content, isActive }) => (
    <CustomCardHeader
        title={title}
        action={
            <PopoverComponent id={id} content={content}/>
        }
        isActive={isActive}
    />
);

const CustomButton = styled(Button) ({
    height: '40px',
    textTransform: 'none',
    color: 'rgb(0, 0, 0)',
    fontFamily: 'MAN EUROPE_Bold',
    fontSize: 18,
    color: 'rgb(48, 60, 73)',
});

const Filters = ({ 
    subTab,
    options,
    setOptions,
    selectedOptions,
    setInitialOptions, 
    setSelectedOptions,
    setCardsData,
    tableData,
    setTableData,
    setInitialTableData,
    minValue, 
    setMinValue,
    maxValue, 
    setMaxValue,
    initialized,
    setInitialized,
    searchVins,
    setSearchVins,
    uploadedFileName, 
    setUploadedFileName,
    activeFilter,
    setActiveFilter,
    setCsvFile,
    submitClicked,
    updateClicked,
    checkedfilters,
    setcheckedfilters,
    appliedfilters,
    setappliedfilters,
    mainfilters,
    setmainfilters,
    disabled,
    handleFiltersModification,
    headerNames,
    handleUpdate, 
    handleReset, 
    handleSubmit
    }) => {
    const { filters,updateGraphData } = useGraphContext();
    const { reset, setreset } = useResetContext();

    // Define popover content for each column
    const popoverContent = {
        'Year of operation': `
            <ul>
                <li>A "Year of operation" is a year in which a vehicle was operated and operational data of the vehicle is available.</li>
                <li>A vehicle can have several "Years of operation" as it usually operates for several years.</li>
            </ul>
            `, 
        'start_of_operation__year': `
            <ul>
                <li>The "Year of commissioning" is the year in which the vehicle was initially starting its operation with a customer.</li>
                <li>A vehicle has exactly one "Year of commissioning".</li>
                <li>Vehicle statistics on "Year of commissioning" might not be statistically representative - e.g. vehicles with special body builts need some ramp-up time to be fully operative.</li>
            </ul>
            `,
        'segment': `
            <ul>
                <li>High level categorisation of MAN's product portfolio</li>
            </ul>
            `,
        'type_number': `
            <ul>
                <li>A vehicle type represents a cluster of several configuration options </li>
                <li>The first three characters of the short vehicle identification number ("VIN short") also represent the type number.</li>
                <li>Example for type number 06X</li>
                <ul>
                    <li>Series = "TGX"</li>
                    <li>Wheel configuration "04x02"</li>
                    <li>Suspension type "BL"</li>
                    <li>Gross vehicle weight = 18t</li>
                    <li>Vehicle type = "SA" or "LK"</li>
                    <li>Cabin = "XM" or "XL" or "XX"</li>
                </ul>
                <li>For more information, visit the below links</li>
                <ul>
                <li>For Truck Euro 6 visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/tg3/model_year_2024/tg3_fzgprogr_e6_mj24.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">tg3_fzgprogr_e6_mj24.pdf (man.eu)</a></li>
                <li>For eTruck visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/etruck/eTrucks_FzgProgr.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">eTrucks_FzgProgr.pdf (man.eu)</a></li>
                <li>For bus visit "Bus Program Poster 2024": <a href="https://mybus.man.eu/page/2462/2463" target="_blank" style="text-decoration: none; color:#0000EE;">MAN MyBus</a></li>
                </ul>
            </ul>
            `,
        'vehicle_type': `
            A vehicle type represents a cluster of several configuration options. Most heavily used connected vehicle types (truck):
            <ul>
                <li>SA: Tractor (German: Sattel)</li>
                <li>LK: Chassis (German: LKW)</li>
                <li>KI: Tipper (German: Kipper)</li>
                <li>WB: Chassis swap body (German: Wechselbrücke)</li>
                <li>KL: Flat bed with crane (German: Kipper Ladekran)</li>
                <li>TM: Concrete mixer (German: Transportmischer, Betonmischer)</li>
            </ul>
            Attribute not available for busses
            `,
        'series': `
            High level categorisation of MAN's product portfolio
            <ul>
                <li>For Truck Euro 6 visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/tg3/model_year_2024/tg3_fzgprogr_e6_mj24.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">tg3_fzgprogr_e6_mj24.pdf (man.eu)</a></li>
                <li>For eTruck visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/etruck/eTrucks_FzgProgr.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">eTrucks_FzgProgr.pdf (man.eu)</a></li>
                <li>For bus visit "Bus Program Poster 2024": <a href="https://mybus.man.eu/page/2462/2463" target="_blank" style="text-decoration: none; color:#0000EE;">MAN MyBus</a></li>
            </ul>
            `,
        'cabin': `
            Truck cabin
            <ul>
                <li>For Truck Euro 6 visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/tg3/model_year_2024/tg3_fzgprogr_e6_mj24.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">tg3_fzgprogr_e6_mj24.pdf (man.eu)</a></li>
                <li>For eTruck visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/etruck/eTrucks_FzgProgr.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">eTrucks_FzgProgr.pdf (man.eu)</a></li>
            </ul>
            Attribute not available for busses
            `,
        'body_built': `
            Body built on top of the truck - based on so called "MORE" segments.<br /> 
            For Truck info visit: <a href="https://manonlineservices.sharepoint.com/:b:/s/BigData/Ef66ISJqZ3BMr1SuPVEzwpcBBgACQNxw_8z40RgOlT-TZA?e=qj3Hzl" target="_blank" style="text-decoration: none; color:#0000EE;">MOREundAUFBAUTEN.UC_2018ffDoppelabfrageODBC...2020b 1.pdf</a>
            Note:
            <ul>
            <li>If body built not part of "MORE" segments, it will be stated as "undefined"</li>
            <li>Quality of documentation highly depend on MAN's vehicle preconfiguration and manual documentation processes</li>
            </ul>
            Attribute not available for busses
            `,
        'power__hp': `
            <ul>
                <li>Power of drivetrain in hp</li>
            </ul>
            `,
        'wheelbase__mm': `
            <ul>
                <li>Wheelbase in mm</li>
            </ul>
            `,
        'wheel_configuration': `
            <ul>
                <li>For Truck Euro 6 visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/tg3/model_year_2024/tg3_fzgprogr_e6_mj24.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">tg3_fzgprogr_e6_mj24.pdf (man.eu)</a></li>
                <li>For eTruck visit: <a href="https://secured.man.eu/media/service/stp/media/de/presences/stp/content_new_tg/sales_argumentation/etruck/eTrucks_FzgProgr.pdf" target="_blank" style="text-decoration: none; color:#0000EE;">eTrucks_FzgProgr.pdf (man.eu)</a></li>
                <li>Attribute not available for busses</li>
            </ul>
            `,
        'suspension': `
            Suspension type - meaning:
            <ul>
                <li>BB: leaf/leaf (German: Blatt/Blatt)</li>
                <li>BL: leaf/air (German: Blatt/Luft)</li>
                <li>LL: air/air (German: Luft/Luft)</li>
                <li>SB (only TGE): coil/leaf (German: Spiral/Blatt)</li>
            </ul>
            Attribute not available for busses
            `,
        'gross_vehicle_weight__t': `
            <ul>
                <li>Weight of the vehicle as built in tons</li>
            </ul>
            `,
        'gross_train_weight__t': `
            <ul>
                <li>Combined weight of the vehicle and its trailer (incl. load) in kilogram</li>
            </ul>
            `,
        'axle_ratio_num': `
            <ul>
                <li>Gear ratio of driven axle</li>
            </ul>
            `,
        'latest_hardware_serial_nr': `
            <ul>
                <li>MAN number for ECU hardware</li>
            </ul>
            `,
        'latest_software_serial_nr': `
            <ul>
                <li>MAN number for ECU software</li>
            </ul>
            `,
        'ecu_fullname': `
            <ul>
                <li>Full name of ECU hardware</li>
                <li>For CORE vehicles select "ZTR-CVM3-MAN"</li>
            </ul>
            `,
        'Yearly mileage [km]' : `
            <ul>
                <li>Minimum acceptable value for km per year is 0</li>
                <li>Maximum acceptable value "500.000"</li>
                <li>Values outside this range will be adjusted accordingly.</li>
                <li>Note: Negative values are not accepted</li>
            </ul>`,
        'VIN short':`
            <ul>
                <li>VIN short = short vehicle identification number</li>
                <li>VIN short filter is given first priority for graph generation.<br /> 
                Additionally the other filter options can be applied (e.g. year of operation).</li>
                <li>Two options to provide VIN short(s)</li>
                    <ul>
                        <li>Type in VIN short (comma seperated, case sensitive (capital letters), max. 30 characters)</li>
                        <li>Upload csv-file (list of VIN shorts without header (1 column, n rows), case sensitive (only capital letters))</li>
                    </ul>
            </ul>`,
        // 'motortyp':`
        //     <ul>
        //         <li>motortyp</li>
        //     </ul>`,
        // 'emission_norm':`
        //     <ul>
        //         <li>emission_norm</li>
        //     </ul>`,
        // 'typ':`
        //     <ul>
        //         <li>typ</li>
        //     </ul>`,
        // 'typ_tg3':`
        //     <ul>
        //         <li>typ_tg3</li>
        //     </ul>`,
        // Add more mappings as needed
    };
    
    const [prevSubTab, setPrevSubTab] = useState(subTab);  
    const [loading, setLoading] = useState(true);     

    useEffect(() => {
        if (subTab !== prevSubTab) {
            // Reset options if the subTab changes
            setInitialized(false);
            setPrevSubTab(subTab);
            // setSelectedOptions({});
        } else {
            setLoading(false);
        }
    }, [subTab]);

    useEffect(() => {
        if (!initialized) {
            setLoading(false);
            fetchInitialData();
            
        } else {
            setLoading(false);
        }
    }, [ initialized]); // Only run on initialization

    useEffect(() => {
        console.log('Current selectedOptions:', selectedOptions);
    }, [selectedOptions]);

    const fetchInitialData = () => {

        setLoading(true); // Set loading to true when fetching data

        let baseURL;
        if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
            baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'md8gpfz52p.eu-west-1.awsapprunner.com') {
            baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'vuca-dev.simbaanalytics-dev.rio.cloud') {
            baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === '8jmq9udcvt.eu-west-1.awsapprunner.com') {
            baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'vuca-prod.simbaanalytics-prod.rio.cloud') {
            baseURL = 'https://x6a2rwxqjqtabc6ei2744e2zvq0ogypf.lambda-url.eu-west-1.on.aws';
          } else {
            baseURL = 'http://localhost:5000'; 
          }
      
        // Include subTab in the request params
        axios.get(`${baseURL}/api/options`, {
            params: { subTab } // Send subTab value to backend
        })
        .then(response => {
            const initialOptionsData = response.data;
            const initialSelectedOptions = {};
            initialOptionsData.forEach(column => {
                initialSelectedOptions[column.label] = column.values;
            });

            console.log('initialSelectedOptions123',initialOptionsData)

            // Add Yearly mileage and VIN short filters
            initialSelectedOptions['Yearly mileage [km]'] = [];
            initialSelectedOptions['VIN short'] = [];

            setInitialOptions(initialSelectedOptions);
            
            //  variable for choose filter category component
            setOptions(initialSelectedOptions);
            
            // variable for applied filters component
            // Create a new array for checkedfilters including the additional filters
            const updatedCheckedFilters = [
                ...initialOptionsData,
                { label: 'Yearly mileage [km]', values: [] }, // Add Yearly mileage
                { label: 'VIN short', values: [] }            // Add VIN short
            ];

            // Set checked filters
            setcheckedfilters(updatedCheckedFilters);
            setreset(updatedCheckedFilters);
            console.log('updatedCheckedFilters',updatedCheckedFilters)
            // variable for selection
            setSelectedOptions(initialSelectedOptions);
            setmainfilters(initialSelectedOptions);
            setInitialized(true);
            setLoading(false);

            fetchTables(initialSelectedOptions);
        })
        .catch(error => {
            console.log('Error fetching options:', error);
        });
    };

    useEffect(() => {
        console.log('Options updated:', options);  // This will log the updated value after `setOptions`
    }, [options]);

    useEffect(() => {
        console.log('Options updated12:', checkedfilters);  // This will log the updated value after `setOptions`

    },[checkedfilters])

    useEffect(() => {
        console.log('Options updated1234:', appliedfilters);  // This will log the updated value after `setOptions`
    },[appliedfilters])

    useEffect(() => {
        console.log('Options updated1234567:', mainfilters);  // This will log the updated value after `setOptions`

    },[mainfilters])
    
    useEffect(() => {
        console.log('Updated activeFilter:', activeFilter); // This will show the updated value
    }, [activeFilter]);   
    
    const fetchTables = (selected) => {
        console.log('Fetching data for subTab:', subTab);
        let baseURL;
        if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
            baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'md8gpfz52p.eu-west-1.awsapprunner.com') {
            baseURL = 'https://n7ej4bdnksinm3wyglrzxs2cc40kiisi.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'vuca-dev.simbaanalytics-dev.rio.cloud') {
            baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === '8jmq9udcvt.eu-west-1.awsapprunner.com') {
            baseURL = 'https://7zyyoc7r6gag3q72ygjdgiv3ti0ooviw.lambda-url.eu-west-1.on.aws';
          } else if (window.location.hostname === 'vuca-prod.simbaanalytics-prod.rio.cloud') {
            baseURL = 'https://x6a2rwxqjqtabc6ei2744e2zvq0ogypf.lambda-url.eu-west-1.on.aws';
          } else {
            baseURL = 'http://localhost:5000'; 
          }
      

        axios.get(`${baseURL}/api/tables`, {
            params: { subTab } // Send selected options and subTab to backend
        })
        .then(response => {
            setTableData(response.data);  
            setInitialTableData(response.data);
            console.log(response.data);
        })
        .catch(error => {
            console.error('Error fetching tables', error);
        });
    };

    const handleSelectionChange = (column, selected) => {
        console.log('Selected:', selected,options,mainfilters);
        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            [column]: selected
        }));
        setActiveFilter(column);
        setappliedfilters(prevSelected => ({
            ...prevSelected,
            [column]: selected
        }));

        delete options[column];
        
        // Update table data based on selected options
        fetchTables({ ...selectedOptions, [column]: selected });

        console.log('handleSelectionChange',selectedOptions)

        if (submitClicked) {
            handleFiltersModification();
        }
    };

    const handleFilterChange = (column) => {
        setActiveFilter(column);
    };

    const handleCloseFilter = (filter) => {
        console.log('Before update:', checkedfilters);
        console.log('Closing filter:', options);
    
        // Reset the selected options for the filter to include all available options
        const allOptionsForFilter = checkedfilters.find(({ label }) => label === filter)?.values || []; // Get all options for the filter

        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            [filter]: allOptionsForFilter, //  // Set to include all options
        }));

        // Reset applied filters for the specific filter
        setappliedfilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
            delete updatedFilters[filter]; // Remove the filter from applied filters
            return updatedFilters;
        });

        // Reset specific filter states
        if (filter === 'Yearly mileage [km]') {
            setMinValue(0);
            setMaxValue(500000);
        } else if (filter === 'VIN short') {
            setSearchVins('');
            setCsvFile(null);
            setUploadedFileName('');
        }
    
        setActiveFilter('');

        setOptions(prevOptions => {
            const entries = Object.entries(prevOptions);
            const filteredEntries = entries.filter(([key]) => key !== filter);
    
            // Get the original index of the filter from checkedfilters
            const originalIndex = checkedfilters.findIndex(({ label }) => label === filter);
            
            // Reinsert the filter at its original position
            if (originalIndex !== -1) {
                filteredEntries.splice(originalIndex, 0, [filter, []]); 
            }
    
            return Object.fromEntries(filteredEntries);
        });
    };

    const handleMinValueChange = (event) => {
        let value = parseInt(event.target.value);
        if (value < 0) {
            value = 0; 
        }
        setMinValue(value);
    
        // Update selected options
        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            'Yearly mileage [km]': [value, maxValue]
        }));
    
        // Update applied filters
        setappliedfilters(prevSelected => ({
            ...prevSelected,
            'Yearly mileage [km]': [value, maxValue]
        }));
        delete options['Yearly mileage [km]'];
    };    
    
    const handleMaxValueChange = (event) => {
        let value = parseInt(event.target.value);
        if (value > 500000) {
            value = 500000; 
        }
        setMaxValue(value);
    
        // Update selected options
        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            'Yearly mileage [km]': [minValue, value]
        }));
    
        // Update applied filters
        setappliedfilters(prevSelected => ({
            ...prevSelected,
            'Yearly mileage [km]': [minValue, value]
        }));
        delete options['Yearly mileage [km]'];
    };    

    const handleSearchVinsChange = (event) => {
        const value = event.target.value.slice(0, 30);
        setSearchVins(value);
    
        // Update selected options
        setSelectedOptions(prevSelected => ({
            ...prevSelected,
            'VIN short': value ? [value] : []
        }));
    
        // Update applied filters
        setappliedfilters(prevSelected => ({
            ...prevSelected,
            'VIN short': value ? [value] : []
        }));
        delete options['VIN short'];
    };  

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCsvFile(file);
            setUploadedFileName(file.name);
    
            // Directly update selected options for the VIN short filter
            setSelectedOptions(prevSelected => ({
                ...prevSelected,
                'VIN short': [file.name] // Set the uploaded file name or any specific value
            }));
    
            // Optionally, set the active filter to vin_short
            setActiveFilter('VIN short'); // Optional

            setappliedfilters(prevSelected => ({
                ...prevSelected,
                'VIN short': [file.name] // Set the uploaded file name or any specific value
            }));

            delete options['VIN short'];
        } else {
            setCsvFile(null);
            setUploadedFileName('');
        }
    }; 

    const handleDownloadTemplate = () => {
        const link = document.createElement('a');
        link.href = '/templates/VUCA_Template_VIN-short.csv'; // Specify the path to your template file
        link.setAttribute('download', ' VUCA_Template_VIN-short.csv'); // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderFilterOptions = (filterOptions) => {
        return Object.keys(filterOptions).map((key) => {
            const isActive = activeFilter === key; // Check if this filter is the active one
            return (
                <div key={key} style={{ marginBottom: '15px' }} onClick={() => handleFilterChange(key)}>
                    <CustomCardHeaderWithPopover
                        title={headerNames[key] || key} // Use the key as the fallback if no header name is provided
                        id={key}
                        content={popoverContent[key]} // Access content from the dictionary
                        isActive={isActive} // Pass the isActive prop
                    />
                </div>
            );
        });
    };
    
    const renderCards = () => {
        if (!options || (Array.isArray(options) && options.length === 0) || (!Array.isArray(options) && Object.keys(options).length === 0)) {
            return <Spinner />;
        }      

    return (
        <div style={{ 
            display: 'flex', 
            width: '60vw',
            height: '600px',
            background: 'white', 
            padding: '5px', 
            // border: '2px solid #f0f0f0'
        }}>
            {/* First Column: Filter Names and Popovers */}
            <div style={{ 
                border: '2px solid #f0f0f0', 
                padding: '0px', 
                flex: '0 0 20%', 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column' 
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 style={{ 
                        background: 'white', 
                        padding: '0px 10px', 
                        fontWeight: 'normal', 
                        textAlign: 'left', 
                        fontFamily: 'MAN EUROPE_regular', 
                        fontSize: 20,
                        color: 'rgb(48, 60, 73)',
                        position: 'sticky',  // Make the header sticky
                        top: 0,              // Position it at the top
                        zIndex: 1            // Ensure it stays above other content
                    }}>
                        Choose filter category
                    </h3>
                    </div>
                <div style={{ background: 'rgb(248, 248, 248)', padding: '15px', overflowY: 'auto',flex: 1 }}>
                    {renderFilterOptions(options)}
                </div>
            </div>

            {/* Image between the first and second columns */}
            <img src={two_way_arrow} style={{ width: '20px', height: '20px', margin: '300px 20px 300px 20px'}} />

            {/* Second Column: Empty */}
            <div style={{ 
                border: '2px solid #f0f0f0', 
                padding: '0px', 
                flex: '0 0 21%', 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                marginRight: '40px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ 
                        background: 'white', 
                        padding: '0px 10px', 
                        fontWeight: 'normal', 
                        textAlign: 'left', 
                        fontFamily: 'MAN EUROPE_regular', 
                        fontSize: 20,
                        color: 'rgb(48, 60, 73)',
                        position: 'sticky',  // Make the header sticky
                        top: 0,              // Position it at the top
                    }}>
                        Applied filters
                    </h3>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomButton onClick={handleReset} disabled={disabled} style={{ marginBottom: '0px', marginRight: '2px' }}>
                            Clear all filters
                        </CustomButton>
                        <img 
                            src={trash} // Replace with your image source path
                            alt="Clear Filters" 
                            style={{ width: '20px', height: '24px', cursor: 'pointer', marginRight: '7px'}} // Adjust size as needed
                            onClick={handleReset} // Optional: make the image clickable to clear filters as well
                        />
                    </div>
                </div>
                <div style={{ background: 'rgb(248, 248, 248)', padding: '15px', overflowY: 'auto',flex: 1 }}>
                {Object.keys(appliedfilters).map((filter) => {
                    // Check if appliedFilters exists and has a length (including empty)
                    if (appliedfilters[filter] && (appliedfilters[filter].length > 0 || selectedOptions[filter].length === 0)) {
                        const isActive = activeFilter === filter; // Check if this filter is the active one
                        return (
                            <div key={filter} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', backgroundColor: isActive ? 'rgb(203, 211, 220)' : 'white' }}  >
                                <div style={{ flex: 1 }} onClick={() => handleFilterChange(filter)}>
                                    <CustomCardHeaderWithPopover
                                        title={headerNames[filter] || filter}
                                        id={filter}
                                        content={popoverContent[filter]}
                                        isActive={isActive} // Pass the isActive prop
                                    />
                                </div>
                                <button onClick={() => handleCloseFilter(filter)} style={{ border: 'none', background: 'none', padding: 0 }}>
                                    <img 
                                        src={closing_icon} // Replace with your image path
                                        alt="Close"
                                        style={{ width: '15px', height: '15px', marginRight: '6px' }} // Ensure the image has the desired size
                                    />
                                </button>
                            </div>
                        );
                    }
                    return null; // Return null if no valid selected options
                })}
                </div>
            </div>
            
            {/* Third Column: Selected Filter's Input Fields */}
            <div style={{ 
                border: '2px solid #f0f0f0', 
                padding: '0px', 
                flex: '1', 
                height: '100%', 
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ 
                        background: 'white', 
                        padding: '0px 10px', 
                        fontWeight: 'normal', 
                        textAlign: 'left', 
                        fontFamily: 'MAN EUROPE_regular', 
                        fontSize: 20,
                        color: 'rgb(48, 60, 73)'
                    }}>
                        Adjust filter details
                    </h3>
                    <div style={{ display: 'flex', alignItems: 'center' }}> {/* Use gap for spacing */}
                        <CustomButton onClick={handleUpdate} disabled={disabled} sx={{ marginLeft: '9px', marginTop: '2px'}}>
                            Refine
                        </CustomButton>
                        <img 
                            src={clear_filters} // Replace with your image source path
                            alt="refine" 
                            style={{ width: '24px', height: '24px', cursor: 'pointer', marginRight: '7px'}} // Adjust size as needed
                            onClick={handleUpdate} // Optional: make the image clickable to clear filters as well
                        />
                    </div>
                </div>
                <div style={{ background: 'rgb(248, 248, 248)', padding: '15px', height: '82%' }}>
                    {activeFilter === '' ? (
                        <Typography variant='body1' style={{ fontSize: '20px',color: 'rgb(48, 60, 73)',textAlign: 'center' }}>
                            No filter selected
                        </Typography>
                    ) : (
                        <>
                            <div style={{ backgroundColor: 'white', padding: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',textAlign:'left' }}>
                                <Typography style={{ fontFamily: 'MAN EUROPE_regular', fontWeight: 'bold', color: 'rgb(48, 60, 73)' }}>
                                    {headerNames[activeFilter] || activeFilter}
                                </Typography>
                                {activeFilter === 'VIN short' && (
                                    <img
                                        src={downloadTemplate} // Make sure to import your download icon at the top
                                        alt="Download Template"
                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                        onClick={handleDownloadTemplate} // Call the download function on click
                                    />
                                )}
                            </div>
                            {activeFilter === 'Yearly mileage [km]' && (
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2px', backgroundColor: 'white', padding:'20px'}}>
                                    <TextField
                                        label='Min Value'
                                        type='number'
                                        value={minValue}
                                        onChange={handleMinValueChange}
                                        style={{ marginRight: '8px' }}
                                        disabled={disabled}
                                    />
                                    <TextField
                                        label='Max Value'
                                        type='number'
                                        value={maxValue}
                                        onChange={handleMaxValueChange}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                            {activeFilter === 'VIN short' && (
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2px', backgroundColor: 'white', padding:'20px' }}>
                                    <TextField
                                        value={searchVins}
                                        onChange={handleSearchVinsChange}
                                        placeholder='Search Vins (max 30 characters)'
                                        style={{ marginBottom: '10px' }}
                                        disabled={disabled}
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type='file'
                                            accept='.csv'
                                            onChange={handleFileUpload}
                                            style={{
                                                fontSize: '20px',
                                                marginBottom: '10px',
                                                display: 'none'
                                            }}
                                            id="file-upload"
                                        />
                                        <label htmlFor="file-upload">
                                            <Button
                                                variant="contained"
                                                component="span"
                                                style={{ 
                                                    marginBottom: '10px',
                                                    marginRight: '10px',
                                                    backgroundColor:'#F0F0F0',
                                                    color: 'black',
                                                    fontFamily: 'MAN EUROPE_regular',
                                                    fontSize: '20px',
                                                    padding: '0 4px 0 4px',
                                                    border: '1px solid black',
                                                    paddingTop: '1px',
                                                    boxShadow: 'none',
                                                    textTransform: 'none',
                                                }}
                                                disabled={disabled}
                                            >
                                                Choose File
                                            </Button>
                                        </label>
                                        <Typography variant='body1' style={{ fontSize: '20px', marginBottom: '10px'}}>
                                            {uploadedFileName || "No file chosen"}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            {(activeFilter !== 'Yearly mileage [km]' && activeFilter !== 'VIN short') && (
                                <div style={{ backgroundColor: 'white', padding: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <ScrollableContainer
                                            sx={{ flex: 1, border: '1px solid #CBD3DC', padding: '3px' }}
                                        >
                                            {loading ? (
                                                <Spinner /> // Display spinner if options are loading
                                            ) : (
                                                <MultiSelectCheckbox
                                                    key={activeFilter}
                                                    label={activeFilter} // Show the active filter label
                                                    options={ (checkedfilters.find(o => o.label === activeFilter)?.values || []) }
                                                    onChange={(selected) => handleSelectionChange(activeFilter, selected, options)}
                                                    selected={selectedOptions[activeFilter] || []} // Use empty array if no selected options
                                                    disabled={disabled || updateClicked}
                                                />
                                            )}
                                        </ScrollableContainer>
                                        {activeFilter !== "Year of operation" && (
                                            <div style={{ flex: 1, border: '1px solid #CBD3DC', height: '306px', overflowX: 'auto' }}>
                                                {tableData[activeFilter] ? (
                                                    <Plot 
                                                        data={tableData[activeFilter].data}
                                                        layout={tableData[activeFilter].layout}
                                                        config={{ responsive: true }}
                                                        style={{ width: '100%', height: '100%' }}
                                                        useResizeHandler={true}
                                                        autosize={true}
                                                    />
                                                ) : null} {/* Render nothing if data is absent */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

return (
    <div style={{ padding: '15px' }}>
        <Box>
            {renderCards()}
        </Box>
        <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end' }}>
            <CustomButton 
                onClick={() => handleSubmit({ selectedValues: selectedOptions })} 
                disabled={disabled} 
                sx={{ 
                    marginLeft: '0px', 
                    marginTop: '0px', 
                    backgroundColor: 'rgb(48, 60, 73)', 
                    color: 'white', 
                    fontWeight: 'normal',
                    '&:hover': {
                        backgroundColor: 'rgb(48, 60, 73)', // Maintain background color on hover
                    },
                    '&:active': {
                        backgroundColor: 'rgb(48, 60, 73)', // Maintain background color on active
                    },
                    '&:focus': {
                        outline: 'none', // Remove focus outline
                    }
                }}
            >
                Submit
            </CustomButton>
        </div>
    </div>
);
};

export default Filters;

    