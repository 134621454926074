import { LogLevel } from '@azure/msal-browser';
import { Configuration } from "@azure/msal-browser";
 
 
function getAuthConfig() {
  // Determine the correct redirectUri and clientId based on some condition
  // This is just an example, replace with your own logic
  if (window.location.hostname === 'vuca-prod.simbaanalytics-dev.rio.cloud') {
    return {
      redirectUri: "https://vuca-prod.simbaanalytics-dev.rio.cloud/",
      clientId: "59f98962-e877-44b7-a2e7-a2cf108972b4",
    };
  } else if (window.location.hostname === 'vuca-prod.simbaanalytics-prod.rio.cloud') {
    return {
      redirectUri: "https://vuca-prod.simbaanalytics-prod.rio.cloud/",
      clientId: "59f98962-e877-44b7-a2e7-a2cf108972b4", // Add the correct clientId here
    };
  } else {
    return {
      redirectUri: "https://vuca-dev.simbaanalytics-dev.rio.cloud/",
      clientId: "b3b5a146-f5f6-412a-9093-939a5b8ca883"
    };
  }
  
}
 
const authConfig = getAuthConfig();
 
const msalConfig = {
    auth: {
      clientId: authConfig.clientId,
      authority: "https://login.microsoftonline.com/ccc63daa-d84b-4abd-98fd-4eeb839de273",
      redirectUri: authConfig.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
};
 
export default msalConfig;